import { BaseModel } from "./base.model";

export class User extends BaseModel {
  constructor(
    public first_name?: string,
    public last_name?: string,
    public email?: string,
    public adress?: string,
    public postal_code?: string,
    public city?: string,
    public country?: string,
    public role?: string,
    public access_code?: string,
    public password?: string,
    public language?: string,
    public additional_info?: any,
    public additional_info_form1?: any,
    public additional_info_form2?: any,
    public additional_info_form3?: any,
    public additional_info_form4?: any,
    public registration_completed?: string,
    public profile_image_url?: string,
    public preferred_livestream?: string,
    public job?: string,
    public has_paid?: boolean,
    public latest_login_date?: string
  ) {
    super();
  }
}
